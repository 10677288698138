<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="商品标题:">
                    <el-input class="s-input" v-model="queryParams.title" placeholder="请输入商品标题" clearable></el-input>
                </el-form-item>
<!--                <el-form-item label="品牌名称:">
                    <el-input class="s-input" v-model="queryParams.supplier_name" placeholder="请输入品牌名称" clearable>
                    </el-input>
                </el-form-item>-->
                <el-form-item label="品牌" prop="brand_id">
                  <el-select v-model="queryParams.brand_id" placeholder="请选择品牌" class="handle-select mr10" clearable>
                    <el-option v-for="items in brandList" :key="items.id" :label="items.name" :value="items.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="中台id:">
                    <el-input class="s-input" v-model="queryParams.source_id" placeholder="请输入中台id" clearable>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="扫码规格号:">
                    <el-input class="s-input" v-model="queryParams.goodssn" placeholder="请输入扫码规格号" clearable></el-input>
                </el-form-item> -->
                <el-form-item label="选择状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否精选:">
                    <el-select v-model="queryParams.is_recommand" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="是" :value="2"></el-option>
                        <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="橱窗展示:">
                    <el-select v-model="queryParams.is_hot" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="是" :value="2"></el-option>
                        <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div>
                <el-button type="primary" @click="add()">新增</el-button>
                <el-button type="primary" @click="preChargeCardPayMaxPercentVisible=true">会员卡使用比例</el-button>
            </div>

            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable" style="margin-top:30px">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="source_id" label="中台id" width="120" align="center"></el-table-column>
                <el-table-column prop="supplier_name" label="品牌名称" width="120" align="center">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.brand" type="primary">{{ scope.row.brand.name }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="商品标题" align="center" width="auto"></el-table-column>
                <!-- <el-table-column prop="cate" label="价格" width="80" align="center"></el-table-column> -->
                <el-table-column label="图片" align="center" width="100" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" :class="`column${scope.$index}`" :src="scope.row.thumb" :preview-src-list="[scope.row.thumb]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="costprice" label="进货价" width="80" align="center"></el-table-column>
                <el-table-column prop="marketprice" label="划线价" width="100" align="center"></el-table-column>
                <el-table-column prop="productprice" label="销售价" width="100" align="center"></el-table-column>
                <el-table-column prop="sales" label="销售量" width="100" align="center"></el-table-column>
                <el-table-column prop="stock" label="库存" align="center" width="100"></el-table-column>
                <el-table-column prop="is_spec" label="是否多规格" width="100" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_spec === 1">否</span>
                        <span v-if="scope.row.is_spec === 2">是</span>
                    </template>
                </el-table-column>

                <el-table-column prop="source_name" label="分类" width="100" align="center"></el-table-column>
                <!-- <el-table-column prop="goodssn" label="扫码规格号" width="160" align="center"></el-table-column>
                <el-table-column prop="productsn" label="单规格号" width="160" align="center"></el-table-column> -->
                <el-table-column label="橱窗状态" prop="is_hot" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.is_hot === 0">关闭</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_hot === 2">开启</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 0">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 0)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 0" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                        <el-button v-if="scope.row.is_hot === 0" type="text" class="green" @click="merchantState(scope.row.id,2)">橱窗展示</el-button>
                        <el-button v-if="scope.row.is_hot === 2" type="text" class="red" @click="merchantState(scope.row.id,0)">橱窗关闭</el-button>
                        <el-button v-if="scope.row.is_recommand === 0" type="text" class="green" @click="merchantRecommandState(scope.row.id,2)">推荐精选</el-button>
                        <el-button v-if="scope.row.is_recommand === 2" type="text" class="red" @click="merchantRecommandState(scope.row.id,0)">关闭精选</el-button>
                        <el-button  type="text" style="color:#F56C6C"  @click="delConfirm(scope.row.id,scope.row.title)" >删除</el-button>

                      <!--
                                              <el-button  type="text" class="red" @click="showGoodsChargeCardPayPercent=true">会员卡抵扣比例</el-button>
                      -->

                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />

            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>

            <el-dialog title="设置全局会员储值卡使用比例" :visible.sync="preChargeCardPayMaxPercentVisible" width="480px" center>
                <div class="del-dialog-cnt">
                    <el-form :inline="true" class="demo-form-inline" ref="tradeForm" v-bind:rules="tradeFormRules" v-bind:model="tradeForm" label-position="left" label-suffix=":" size="large" label-width="160px">
                        <el-form-item label="请输入比例">
                            <el-input class="s-input" v-model="tradeForm.pre_charge_card_pay_max_percent" placeholder="请输入抵扣比例" clearable></el-input>
                            <span style="font-size: 18px;font-weight: bold">&nbsp;%</span>
                        </el-form-item>
                    </el-form>
                    比例范围：0~100%，0%则不可使用会员卡抵扣，100%则可使用会员卡抵扣全部商品金额
                    设置全局比例，如果单商品没设置，则按全局比例抵扣，如果设置单商品，则按单商品比例抵扣
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitTradeForm">提 交</el-button>
                </span>
            </el-dialog>

<!--            &lt;!&ndash; 商品使用会员储值卡抵扣比例 &ndash;&gt;
            <el-dialog title="设置商品会员储值卡抵扣比例" :visible.sync="goodsPreChargeCardPayMaxPercentVisible" width="480px" center>
                <div class="del-dialog-cnt">
                    <el-form :inline="true"
                             class="demo-form-inline"
                             ref="goodsChargeCardPayPercentForm"
                             v-bind:rules="goodsChargeCardPayPercentFormRules"
                             v-bind:model="goodsChargeCardPayPercentForm"
                             label-position="left"
                             label-suffix=":"
                             size="large"
                             label-width="160px">
                        <el-form-item label="请输入比例">
                            <el-input class="s-input" v-model="goodsChargeCardPayPercentForm.charge_card_pay_percent" placeholder="请输入抵扣比例" clearable></el-input>
                            <span style="font-size: 18px;font-weight: bold">&nbsp;%</span>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitTradeForm">提 交</el-button>
                </span>
            </el-dialog>-->
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import {deal} from '@/common/main';

export default {
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            defaultImg: require('../../assets/img/zhan.jpeg'),
            menuList: ['商品', '商品管理'],
            loading: false,
            title: '加载中',
            checked: "true",
            addVisible: false,
            form: {
                state: 1
            },
            rules: {

            },
            pageInfo: {
                list: [],
            },
            goodsList: [],
            show: false,
            confirmContent: '',
            confirmVisible: false,
            refunt_state: 0,
            refunt_id: 0,
            queryParams: {
                name: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            beginDate: [],
            brandList: [],
            classfyList: [],
            //设置全局会员储值卡使用比例
            preChargeCardPayMaxPercentVisible: false,
            goodsPreChargeCardPayMaxPercentVisible: true,
            SystemTransactionConfig:null,
            tradeForm: {
                b_domain: '',
                c_domain: '',
                is_renlian: 0,
                is_zgbury: 0,
                private_domain: 0,
                enterprise_user_buy_only:false,
                pre_charge_card_pay_max_percent:30,
            },
            goodsChargeCardPayPercentForm:{
                charge_card_pay_percent:0,
            },
            tradeFormRules: {
                pre_charge_card_pay_max_percent: [{
                    required: true,
                    message: '全局抵扣比例不能为空',
                    trigger: 'blur'
                }]
            },
            goodsChargeCardPayPercentFormRules: {
                charge_card_pay_percent: [{
                    required: true,
                    message: '会员储值卡抵扣比例不能为空',
                    trigger: 'blur'
                }]
            },
            postOptions:{
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
        };
    },
    watch: {
        $route(to, from) {
            let ArticleEdit = from.fullPath.indexOf('/product-listEdit')
            let ArticleAdd = from.fullPath.indexOf('/product-listAdd')
            if (ArticleEdit !== -1 || ArticleAdd !== -1) {
                this.loadData()
            }
        }
    },
    created() {
        this.loadData();
        this.loadTrade();
        this.loadBrand()
    },
    methods: {
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function() {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function() {
                    show[0].style['display'] = 'none'
                })
            })
        },
        // 橱窗
        merchantState(id, state) {
            request.post('/goods/window/goods/edit', { id: id, is_hot: state },this.postOptions).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        merchantRecommandState(id, state) {
            request.post('/goods/window/goods/edit', { id: id, is_recommand: state },this.postOptions ).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'thumb', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        cellstyle() {
            return "text-align: center";
        },

        add() {
            this.mode = 'add';
            this.title = '新增';
             this.$router.push({ path: '/product-listAdd' })

           // request.post('/middle/partner/login', {}).then(res => {
           //     localStorage.setItem('middle-token', res.data.tokenExt)
           //     this.$router.push({ path: '/product-LiHanAdd' })
           // })

            // console.log();


        },
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            this.$router.push({ path: '/product-listEdit', query: { id: row.id } })
        },
        delConfirm(goodsId,name){
          this.$confirm('此操作将永久删除商品【'+name+'】, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            request.get('/goods/delete?id='+goodsId,{}).then(ret => {
              if (ret.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                // 重新请求数据
                this.loadData()

              } else {
                this.$message.error(ret.msg);
              }
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        },
        loadData() {
            this.loading = true;
            // 获取商品列表
            request.get('/goods/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function(val) {
                        if (val.goods && val.goods[0]) {
                            val.goods = val.goods[0].list;
                        }
                        return val;
                    });
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        changeBegin(r) {
            this.queryParams.begin_time = this.beginDate[0];
            this.queryParams.end_time = this.beginDate[1];
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/goods/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存交易配置
        submitTradeForm() {
            let _this = this
            request.post('/system/transaction/edit', this.tradeForm).then(ret => {
                if (ret.code === 1) {
                    _this.loadTrade()
                    this.$refs.tradeForm.clearValidate()
                    this.$message.success('支付配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取交易配置
        loadTrade() {
            this.loading = true;
            request.get('/system/transaction/list').then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.tradeForm = ret.data;
                    //this.$refs.tradeForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadBrand(){
            request.get('/goods/brand/list', { page: 1, pagesize: -1, state: 2 }).then((ret) => {
              if (ret.code == 1) {
                this.brandList = ret.data.list;
              } else {
                this.$message.error(ret.msg);
              }
            });
        },
        showGoodsChargeCardPayPercent(goodsItem){
            this.goodsPreChargeCardPayMaxPercentVisible=true
            this.goodsChargeCardPayPercentForm=goodsItem
        }
    }
};
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 200px;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader {
    padding-bottom: 13px;
}

.el-icon-plus avatar-uploader-icon {
    margin-right: 50px;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
    line-height: 110px;
    text-align: center;
}

.avatar-uploader .avatar-uploader-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
    font-size: 28px;
    line-height: 100px;
}
</style>